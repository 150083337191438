import { ApolloClient, gql } from "@apollo/client";
import {
  AppSessionId,
  HexVersionId,
  ReactiveParamType,
  SchemaElementSearchResultType,
  ScopeItemType,
  SqlDialect,
  VariableName,
  retry,
  typedObjectEntries,
} from "@hex/common";
import { editor as Editor, Position, languages } from "monaco-editor";

import { ScopeItemFragment } from "../../../appsession-multiplayer/AppSessionMPModel.generated";
import { SchemaElementSearchColumnFilter } from "../../../generated/graphqlTypes.js";
import { RootStore } from "../../../redux/hooks";
import { appSessionMPSelectors } from "../../../redux/slices/appSessionMPSlice";
import { hexVersionMPSelectors } from "../../../redux/slices/hexVersionMPSlice";
import { projectGraphV3Selectors } from "../../../redux/slices/projectGraphV3Slice";
import { getCellIdForModel } from "../../../state/models/useModel";
import { logErrorMsg, logInfoMsg } from "../../../util/logging";

import {
  SchemaSearchHelpers,
  getCompletionItemsForModelAtPosition,
} from "./sqlCompletionItems";
import {
  ColumnSearchResultsForSqlCompletionFragment,
  TableSearchResultsForSqlCompletionFragment,
} from "./sqlCompletionItems.generated";
import {
  GetDataConnectionDetailsForSqlCompletionProviderDocument,
  GetDataConnectionDetailsForSqlCompletionProviderQuery,
  GetDataConnectionDetailsForSqlCompletionProviderQueryVariables,
  SearchSchemaForSqlCompletionProviderDocument,
  SearchSchemaForSqlCompletionProviderQuery,
  SearchSchemaForSqlCompletionProviderQueryVariables,
} from "./SqlCompletionProvider.generated";

gql`
  query GetDataConnectionDetailsForSqlCompletionProvider(
    $dataConnectionId: DataConnectionId!
  ) {
    dataConnectionSchemas {
      connectionType
      connectionMetadata {
        multiDatabase
      }
    }
    dataConnectionById(dataConnectionId: $dataConnectionId) {
      id
      defaultDatabase {
        id
        name
      }
      connectionType
    }
  }
`;

gql`
  query SearchSchemaForSqlCompletionProvider(
    $dataConnectionId: [DataConnectionId!]!
    $query: String!
    $resultTypes: [SchemaElementSearchResultType!]!
    $schemaFilters: [SchemaElementSearchSchemaFilter!]
    $tableFilters: [SchemaElementSearchTableFilter!]
    $columnFilters: [SchemaElementSearchColumnFilter!]
  ) {
    searchSchemaElements(
      dataConnectionId: $dataConnectionId
      searchQuery: $query
      resultTypes: $resultTypes
      schemaFilters: $schemaFilters
      tableFilters: $tableFilters
      columnFilters: $columnFilters
      algorithm: PREFIX
      ignoreMetadata: true
    ) {
      databases {
        ...DatabaseSearchResultsForSqlCompletion
      }
      schemas {
        ...SchemaSearchResultsForSqlCompletion
      }
      tables {
        ...TableSearchResultsForSqlCompletion
      }
      columns {
        ...ColumnSearchResultsForSqlCompletion
      }
    }
  }
`;

/** Class to provide smart Monaco autocomplete suggestions for SQL-based cells. */
export class SqlCompletionProvider implements languages.CompletionItemProvider {
  private apolloClient?: ApolloClient<unknown>;
  private store?: RootStore;
  private hexVersionId?: HexVersionId;
  private appSessionId?: AppSessionId;
  private enableSqlCompletionProviderLogs: boolean = false;

  triggerCharacters = ["."];

  public update({
    enableSqlCompletionProviderLogs,
    newApolloClient,
    newAppSessionId,
    newHexVersionId,
    newStore,
  }: {
    newApolloClient?: ApolloClient<unknown>;
    newStore?: RootStore;
    newHexVersionId?: HexVersionId;
    newAppSessionId?: AppSessionId;
    enableSqlCompletionProviderLogs?: boolean;
  }): void {
    this.apolloClient = newApolloClient;
    this.store = newStore;
    this.hexVersionId = newHexVersionId;
    this.appSessionId = newAppSessionId;
    this.enableSqlCompletionProviderLogs =
      enableSqlCompletionProviderLogs ?? false;
  }

  private logError(message: string): void {
    logErrorMsg(message, "SqlCompletionProvider");
  }

  async provideCompletionItems(
    model: Editor.ITextModel,
    position: Position,
  ): Promise<languages.CompletionList> {
    if (this.enableSqlCompletionProviderLogs) {
      logInfoMsg("providing completion items", {
        safe: {
          appSessionId: this.appSessionId,
          hexVersionid: this.hexVersionId,
        },
      });
    }

    if (model.isDisposed()) {
      if (this.enableSqlCompletionProviderLogs) {
        logInfoMsg("model is disposed. Returning no suggestions", {
          safe: {
            appSessionId: this.appSessionId,
            hexVersionid: this.hexVersionId,
          },
        });
      }
      return { suggestions: [] };
    }

    const { apolloClient, appSessionId, hexVersionId, store } = this;
    if (
      apolloClient == null ||
      store == null ||
      hexVersionId == null ||
      appSessionId == null
    ) {
      this.logError("Need to call update at least once");
      return { suggestions: [] };
    }

    const cellId = getCellIdForModel(model);
    if (cellId == null) {
      this.logError("No cell matching model");
      return { suggestions: [] };
    }

    const hexVersion = hexVersionMPSelectors
      .getHexVersionSelectors(hexVersionId)
      .select(store.getState());

    const cellContents = hexVersionMPSelectors
      .getCellContentSelectors(hexVersionId)
      .selectByCellId(store.getState(), cellId);

    if (cellContents == null) {
      this.logError("could not load cell details");
      return { suggestions: [] };
    }

    if (cellContents.__typename !== "SqlCell") {
      this.logError("called for a non-sql cell");
      return { suggestions: [] };
    }

    // For now, we use the same autocomplete parser for all SQL dialects for simplicity.
    // Eventually, we might want to try to have custom parsers depending on the dialect,
    // see https://docs.gethue.com/developer/components/parsers/
    // We load this dymnamically here because it's a fairly large file we don't want included by default.
    const { default: parser } = await retry({
      func: () => import("@hex/sql-autocomplete-parser"),
      retryCount: 5,
      interval: 150,
    });

    let defaultDatabaseName = undefined;
    let schemaSearch: SchemaSearchHelpers;

    let suggestDatabases = false;
    let dialect: SqlDialect = "duckdb";
    if (cellContents.connectionId) {
      const dataConnectionId = cellContents.connectionId;

      let matchingCTEs: VariableName[] = [];
      let cteDfs: ScopeItemFragment[] = [];

      if (hexVersion.graphV3) {
        const graphNode = projectGraphV3Selectors
          .getGraphNodeV3Selectors(hexVersionId)
          .selectById(store.getState(), cellId);
        if (graphNode != null) {
          if (graphNode.type !== "cell") {
            this.logError(
              `Called for non-cell graph node of type ${graphNode.type}`,
            );
            return { suggestions: [] };
          }
          matchingCTEs = Array.from(graphNode.inputParams)
            .filter(([, value]) => {
              return (
                value.type === ReactiveParamType.QUERY_RESULT ||
                value.type === ReactiveParamType.REMOTE_QUERY_RESULT
              );
            })
            .map(([key]) => key);

          const scope = appSessionMPSelectors
            .getScopeSelectors(appSessionId)
            .selectAll(store.getState());
          cteDfs =
            scope?.filter(
              (obj) =>
                (obj.type === ScopeItemType.DATAFRAME ||
                  obj.type === ScopeItemType.REMOTE_DATAFRAME) &&
                matchingCTEs.includes(obj.name as VariableName),
            ) ?? [];
        } else {
          // We debounce updating the graph (see graphSaga.ts) so it's possible that the
          // graph is out of date. In that case, we just don't show any CTE suggestions.
          this.logError("Could not load graph node. Skipping CTE suggestions.");
        }
      }

      const {
        data: { dataConnectionById: dataConnection, dataConnectionSchemas },
      } = await apolloClient.query<
        GetDataConnectionDetailsForSqlCompletionProviderQuery,
        GetDataConnectionDetailsForSqlCompletionProviderQueryVariables
      >({
        query: GetDataConnectionDetailsForSqlCompletionProviderDocument,
        variables: {
          dataConnectionId,
        },
      });
      dialect = dataConnection.connectionType;

      defaultDatabaseName = dataConnection.defaultDatabase?.name ?? undefined;

      const connectionSchema = dataConnectionSchemas.find(
        ({ connectionType }) =>
          connectionType === dataConnection.connectionType,
      );
      if (connectionSchema == null) {
        this.logError(
          `Could not find connection schema for connectionType=${dataConnection.connectionType}`,
        );
      } else {
        // only suggest database completions for multiDatabase connectors
        suggestDatabases = connectionSchema.connectionMetadata.multiDatabase;
      }

      schemaSearch = {
        getDatabases: async (query) => {
          const data = await apolloClient.query<
            SearchSchemaForSqlCompletionProviderQuery,
            SearchSchemaForSqlCompletionProviderQueryVariables
          >({
            query: SearchSchemaForSqlCompletionProviderDocument,
            variables: {
              dataConnectionId,
              resultTypes: [SchemaElementSearchResultType.DATABASES],
              query,
              schemaFilters: null,
              columnFilters: null,
              tableFilters: null,
            },
          });
          return data.data.searchSchemaElements.databases;
        },
        getSchemas: async (query, { filters } = {}) => {
          const data = await apolloClient.query<
            SearchSchemaForSqlCompletionProviderQuery,
            SearchSchemaForSqlCompletionProviderQueryVariables
          >({
            query: SearchSchemaForSqlCompletionProviderDocument,
            variables: {
              dataConnectionId,
              resultTypes: [SchemaElementSearchResultType.SCHEMAS],
              query,
              schemaFilters: filters ?? null,
              columnFilters: null,
              tableFilters: null,
            },
          });
          return data.data.searchSchemaElements.schemas;
        },
        getTables: async (query, { filters } = {}) => {
          // Splits a search filters into local DF filters vs remote filters by checking if the table exists in scope already as a CTE
          // and matches one of the CTEs used by the cell
          let combinedResultCount = 0;
          const combinedSearchResults = [];

          if (matchingCTEs.length > 0) {
            const tables = matchingCTEs.map(
              (cte) =>
                ({
                  name: cte,
                  __typename: "DataSourceTable",
                  dataSourceSchema: {
                    name: "",
                    __typename: "DataSourceSchema",
                    dataSourceDatabase: {
                      name: "",
                      __typename: "DataSourceDatabase",
                    },
                  },
                }) as const,
            );
            combinedSearchResults.push(...tables);
            combinedResultCount += tables.length;
          }
          const data = await apolloClient.query<
            SearchSchemaForSqlCompletionProviderQuery,
            SearchSchemaForSqlCompletionProviderQueryVariables
          >({
            query: SearchSchemaForSqlCompletionProviderDocument,
            variables: {
              dataConnectionId,
              resultTypes: [SchemaElementSearchResultType.TABLES],
              query,
              schemaFilters: null,
              tableFilters: filters ?? null,
              columnFilters: null,
            },
          });
          const { tableResultCount, tableSearchResults } =
            data.data.searchSchemaElements.tables;
          combinedSearchResults.push(...tableSearchResults);
          combinedResultCount += tableResultCount;
          return {
            __typename: "TableSearchResults",
            tableResultCount: combinedResultCount,
            tableSearchResults: combinedSearchResults,
          };
        },
        getColumns: async (query, { filters } = {}) => {
          // Splits a search filters into local DF filters vs remote filters by checking if the table exists in scope already as a CTE
          // and matches one of the CTEs used by the cell
          const shouldMatchCte = (
            f: SchemaElementSearchColumnFilter,
          ): boolean =>
            f.tableName != null &&
            matchingCTEs.includes(f.tableName as VariableName);
          const cteFilters = (filters ?? [])
            .filter((f) => shouldMatchCte(f))
            .map((f) => f.tableName);
          const remoteFilters = (filters ?? []).filter(
            (f) => !shouldMatchCte(f),
          );
          let combinedResultCount = 0;
          const combinedSearchResults = [];
          const matchingDfs = cteDfs.filter((df) =>
            cteFilters.includes(df.name),
          );
          if (matchingDfs.length > 0) {
            const columns = matchingDfs.flatMap((df) =>
              typedObjectEntries(df.dataFrameSchema?.columns ?? {}).map(
                ([key, c]) =>
                  ({
                    name: key,
                    type: c ?? "",
                    __typename: "DataSourceColumn",
                    dataSourceTable: {
                      name: df.name,
                      __typename: "DataSourceTable",
                      dataSourceSchema: {
                        name: "",
                        __typename: "DataSourceSchema",
                      },
                    },
                  }) as const,
              ),
            );
            combinedSearchResults.push(...columns);
            combinedResultCount += columns.length;
          }
          if (remoteFilters.length > 0 || cteFilters.length === 0) {
            const data = await apolloClient.query<
              SearchSchemaForSqlCompletionProviderQuery,
              SearchSchemaForSqlCompletionProviderQueryVariables
            >({
              query: SearchSchemaForSqlCompletionProviderDocument,
              variables: {
                dataConnectionId,
                resultTypes: [SchemaElementSearchResultType.COLUMNS],
                query,
                schemaFilters: null,
                tableFilters: null,
                columnFilters: remoteFilters ?? null,
              },
            });
            const { columnResultCount, columnSearchResults } =
              data.data.searchSchemaElements.columns;
            combinedSearchResults.push(...columnSearchResults);
            combinedResultCount += columnResultCount;
          }
          return {
            __typename: "ColumnSearchResults",
            columnResultCount: combinedResultCount,
            columnSearchResults: combinedSearchResults,
          };
        },
      };
    } else if (cellContents.dataFrameCell) {
      const scope = appSessionMPSelectors
        .getScopeSelectors(appSessionId)
        .selectAll(store.getState());
      const dfs =
        scope?.filter(
          (obj) =>
            obj.type === ScopeItemType.DATAFRAME ||
            obj.type === ScopeItemType.REMOTE_DATAFRAME,
        ) ?? [];
      schemaSearch = {
        getDatabases: () =>
          Promise.resolve({
            __typename: "DatabaseSearchResults",
            databaseResultCount: 0,
            databaseSearchResults: [],
          }),
        getSchemas: () =>
          Promise.resolve({
            __typename: "SchemaSearchResults",
            schemaResultCount: 0,
            schemaSearchResults: [],
          }),
        // We can safely ignore `query` here and in `getColumns` below because that is
        // only used for backend search, but this is all happening on the client. We can
        // also ignore `filters` for `getTables` because we always want to provide completion
        // for all in-scope dataframes (there's no notion of a schema to filter by for
        // dataframes).
        getTables:
          async (): Promise<TableSearchResultsForSqlCompletionFragment> => {
            const tables = dfs.map(
              (df) =>
                ({
                  name: df.name,
                  dataSourceSchema: {
                    __typename: "DataSourceSchema",
                    name: "",
                    dataSourceDatabase: {
                      __typename: "DataSourceDatabase",
                      name: "",
                    },
                  },
                  __typename: "DataSourceTable",
                }) as const,
            );
            return {
              __typename: "TableSearchResults",
              tableResultCount: tables.length,
              tableSearchResults: tables,
            };
          },
        getColumns: async (
          query,
          { filters } = {},
        ): Promise<ColumnSearchResultsForSqlCompletionFragment> => {
          const matchingDf = dfs.find(
            (df) => df.name === filters?.[0].tableName,
          );
          const columns =
            matchingDf &&
            Object.keys(matchingDf.dataFrameSchema?.columns ?? {}).map(
              (key) =>
                ({
                  name: key,
                  type: matchingDf.dataFrameSchema?.columns[key] ?? "",
                  __typename: "DataSourceColumn",
                  dataSourceTable: {
                    name: matchingDf.name,
                    __typename: "DataSourceTable",
                    dataSourceSchema: {
                      name: "",
                      __typename: "DataSourceSchema",
                    },
                  },
                }) as const,
            );
          return {
            __typename: "ColumnSearchResults",
            columnResultCount: columns?.length ?? 0,
            columnSearchResults: columns ?? [],
          };
        },
      };
    } else {
      schemaSearch = {
        getDatabases: () =>
          Promise.resolve({
            __typename: "DatabaseSearchResults",
            databaseResultCount: 0,
            databaseSearchResults: [],
          }),
        getSchemas: () =>
          Promise.resolve({
            __typename: "SchemaSearchResults",
            schemaResultCount: 0,
            schemaSearchResults: [],
          }),
        getTables: () =>
          Promise.resolve({
            __typename: "TableSearchResults",
            tableResultCount: 0,
            tableSearchResults: [],
          }),
        getColumns: () =>
          Promise.resolve({
            __typename: "ColumnSearchResults",
            columnResultCount: 0,
            columnSearchResults: [],
          }),
      };
    }

    return await getCompletionItemsForModelAtPosition(model, position, {
      schemaSearch,
      parser,
      defaultDatabaseName,
      suggestDatabases,
      dialect,
      enableSqlCompletionProviderLogs: this.enableSqlCompletionProviderLogs,
    });
  }
}

export const SQL_COMPLETION_PROVIDER = new SqlCompletionProvider();
